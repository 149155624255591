// import fonts
$web-font-path: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap';
@import url($web-font-path);

//import variables
@import './_variables.scss';
//import bootstrap
@import "bootstrap/scss/bootstrap.scss";


//custom styles

body {
	background-color: #56576c;
	background-image: url(./images/bkg_mz.jpg);
	background-repeat: repeat;
	background-position: center center;
	position: relative;
	z-index: 0;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
	vertical-align: baseline;
}

#wrapper {
	background-color: $white;
	padding-top:1rem;
}

hr {
	margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #acc5d2;
}
blockquote p {
    font-weight: 400;
    line-height: 1.55;
}

#header {
	h1 {
		font-weight: 700;
		color: $liteBlue;
	}
	ul {
		font-weight: 400;
		padding-left: 15px;
	}

	li span{
		font-weight: bolder;
		color: black;
	}
	.header-image {
		width:100%;
	}
	.storebuttons {
		text-align: center;
		margin-top: 0.5rem;
	}
	button {
		font-weight: bolder;
    	font-family: 'Lato';
    	color: white;
	}
}

#MZContent {
	h2{
		color:$liteBlue;
		margin-bottom: 0px;
	}
	.mz-view {
		margin-bottom: 1em;
	}
	
	.mz-review {
		text-align: center;

		p {
			text-align:left;
		}
	}
	ul {
		padding-left: 15px;
	}
}

.stars {
	width:70px;
	height:14px;
	background-image:url(./images/star.png);	
}

#footer {
	text-align: center;

	.mz-review{
		margin-bottom: 1em;
	}

	.webicon {
		display: block;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		width: 30px;
		height: 30px;
		text-indent: -999em;
		text-align: left;
		-moz-box-shadow: 0 1px 0 rgba(0,0,0,0.5);
		-webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.5);
		box-shadow: 0 1px 0 rgba(0,0,0,0.5);
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
		border-radius: 5px;
	}
	.studio6thave {
		background-image: url("./images/studio6thave.png");
	}
	.facebook {
		background-image: url("./images/facebook.png");
	}
	li {
		display: inline-block;
    	margin: 0 3px;
    	padding: 0;
	}
}